/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../generated/graphql-types";
import { Hero, Statement } from "../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../layouts";
import { GA_CTA } from "../components/GA_CTA";
import { GA_personcarousel } from "../components/GA_personcarousel";
import colleen from "../images/colleen.jpg";
import damian from "../images/damian.jpg";
import socialHead2 from "../images/bestversion.png";

// const component: React.FC<PageProps<Pick<Query, "graphCmsSectorPage">>> = ({
//   path,
//   data: { graphCmsSectorPage: sectorPage },
// }) => {
//   console.log(sectorPage);
//   if (!sectorPage) return null;
//   return (
//     <Layout
//       pagePath={path}
//       menu={buildMenu(sectorPage.menu)}
//       footerMenu={buildMenu(sectorPage.footerMenu)}
//       keywords={sectorPage.keywords}
//       title={sectorPage.pageTitle || sectorPage.sector}
//       description={sectorPage.description}
//     >
//       <Hero
//         title="Partners in Performance Energy Transition Insights"
//         video={getVideo(sectorPage.heroVideo)}
//         image={getImage(sectorPage.heroImage)}
//         short={true}
//         treatment={sectorPage.heroTreatment}
//         showSearch={true}
//       />
//       <Statement
//         title=""
//         headline="A collection of analysis, research and stories about our capabilities from our Energy Transition Experts."
//         id={""}
//         className="custom-class"
//       />
//       <ResearchComponent name="" />
//     </Layout>
//   );
// };

// export default component;

// export const sectorPageQuery = graphql`
//   query sectorPageQueryAndSectorPageQuery($id: String) {
//     graphCmsSectorPage(id: { eq: $id }) {
//       sectorType
//       pageTitle
//       keywords
//       description
//       menu {
//         ...MenuFragment
//       }
//       businessChallengesIntroduction
//       businessChallenges {
//         headline
//         id
//         content {
//           cleaned
//         }
//       }
//       heroImage {
//         ...ImageFragment
//       }
//       statement {
//         cleaned
//       }
//       heroVideo {
//         ...VideoFragment
//       }
//       heroTreatment
//       id
//       keyStatistic
//       leadership {
//         ...ProfileFragment
//       }
//       leadershipTitle
//       quote
//       quoteFrom {
//         ...ProfileFragment
//       }
//       infographicIntroduction {
//         cleaned
//       }
//       infographicDetails {
//         cleaned
//       }
//       genericInfographicText {
//         cleaned
//       }
//       sector
//       slug
//       statisticDetails
//       storiesTitle
//       storiesImage {
//         ...ImageFragment
//       }
//       successes {
//         __typename
//         ... on GraphCMS_CaseStudy {
//           ...CaseStudyReferenceFragment
//         }
//         ... on GraphCMS_Story {
//           ...StoryReferenceFragment
//         }
//         ... on GraphCMS_Insight {
//           ...InsightReferenceFragment
//         }
//       }
//       form {
//         ...FormFragment
//       }
//     }
//   }
// `;

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="diversity | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Be the best version of you"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title="We believe unleashing our individual potential starts when people feel empowered to bring their entire selves to work."
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients.",
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: "Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential.",
              },
            ],
          },
        ]}
        id={""}
        className=""
      />
      {/* <GA_Video id={""} /> */}
      <GA_personcarousel
        id={""}
        content={[
          {
            id: "1",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
          {
            id: "2",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
          {
            id: "3",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
          {
            id: "4",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
          {
            id: "5",
            Name: "Colleen Grady",
            Position: "Partner",
            area: " Australia and New Zealand",
            link: colleen,
            id2: "2",
            Name2: "Damian Lahoud",
            Position2: " Senior Associate",
            area2: "Africa",
            link2: damian,
          },
        ]}
      />
      {/* <GA_imageQuote
        id={""}
        title=""
        Quote="“Having diversity of people, who have different ways of doing or thinking about things, is critical to coming up with the right solutions and answers to stay ahead.”

        Colleen Grady 
        Partner
        Australia and New Zealand
        "
        position="RIGHT"
        image={logo8}
      /> */}
      {/* <GA_QA
        id={""}
        title={[
          {
            label:
              "Q: Why do you think inclusion and diversity are important for ",
            message:
              "I think the world is changing very fast, so companies cannot just ‘do what they have always done’. Technology disruption is impacting every industry, requiring a lot of problem‑solving in change. The key to problem‑solving is having many different options to consider. Every person will have a different perspective, because they’ve grown up with a different background, different family environment, different levels of affluence or educational background, which will help us come up with the right solutions and answers to stay ahead.",
            className: "col-start-2",
          },

          {
            label:
              "Q: How can women today support and empower the next generation of female leaders?",
            message:
              "One is just turning up and being there; being very visible and accessible so if people want to talk to you, you are available. I think giving good quality feedback is important, which is the way you learn and grow. Offering feedback around style and presentation, not at a technical level, but in how you present yourself. ",
            className: "col-start-2",
          },
          {
            label:
              "Q: What do you think we should all be doing to challenge gender bias and inequality??",
            message:
              "Try to challenge stereotypes and generalisations. Individuals within each ‘group’. It can be simple things like challenging language and how people use it – for example if they talk about ‘the sales guy’ or ‘the next CEO, he will be great’. I think it’s just raising those topics and trying to challenge inequality when you see it.",
            className: "col-start-2",
          },
          {
            label:
              "Q: What would you like to see change for women in the consulting industry?",
            message:
              "Many changes I would like for women, I would like for everybody. I would love to see more focus on efficiency and effectiveness during a reasonable number of hours, rather than thinking working more hours is how to display your value. Enabling people to have balance in their personal lives and the ability to interact with their families and their children. We need to keep challenging ourselves on how we support people in different ways to enable them to take on new challenges.",
            className: "col-start-2",
          },
        ]}
        message="We strive to bring the strengths of our organisation and the talents and passion of our people together to create positive impact in society and help make the world a better place for all."
        className=""
      /> */}
      <GA_CTA
        id={""}
        title1="Climate Action"
        title2="Corporate Social Responsibility "
        Link1="/climate-action"
        Link2="/corporate-social-responsibility"
      />
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
